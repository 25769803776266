.nav{
  z-index: 100;;
  position:relative;
  width:100%;
  border-bottom: solid 1px $color-silver;
  background:$color-white;


 .container{
   position:relative;
   display: flex;
   flex-wrap: wrap;
   height:87px;

   @include breakpoint-down(md) {
    height:79px;
  }

    .brand{   
      display: flex;
      text-decoration: none;
      flex-direction: column;
      justify-content:center;
      
      span{
        display:block;
        color:$color-gray;
        text-decoration:none;
        font-size:12.5px;
      }

    }

    .hamburger{
      position:absolute;
      right:0;
      z-index:1;
      top:0;
      border-radius:0;

      //@include breakpoint-down(sm) {
      //  top:43px;
      //}
    }

  }
}

.menu{
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-bottom: 0;

  @include breakpoint-down(md) {
    right:-240px;
    position:absolute;
    background:$color-silver;
    height:100%;
    display: block;
    width: 240px;
  }

  li{
    list-style: none;
    padding: 0 0.7rem;
    float: left;
    display: flex;

     @include breakpoint-down(md) {
      font-size:25px;
      border-bottom:solid 1px white;
      
        &:first-child{
          text-align:center;
          height:81px;
          padding:1.6rem;
          background-color:$color-lightgray;
          text-transform:uppercase;
        }
     }

    a{
      text-decoration: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: relative;
      color:$color-black;
      font-size:0.98rem;
      text-transform:lowercase;
      
      @include breakpoint-down(md) {
        font-size:25px;
      }

      &:hover{
        color:$color-link-hover;
      }

      &.active{
        //color:$color-link-hover;
        position:relative;

        @include breakpoint-up(md) {
          &:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: $color-green;
            position: absolute;
            bottom: 20px;
            left: 50%;
            border-radius: 50%;
            margin-left: -3px;

          }
        }
      }  
    }

    @include breakpoint-down(md) {
        display:block;
        float:none;
        width:100%;

        a{
          display:block;
          padding:1.5rem;
        }
     }

  }
}
