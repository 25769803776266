h1.titles{
  text-transform: uppercase;
  font-size: 1rem;
  background-color: $color-blue;
  position: relative;
  text-align: center;
  line-height: 1rem;
  padding: 11px;
  border-bottom: solid 1px $color-silver;
  margin-bottom: 0;

  &:before{
    content:'';
    height: 4px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -4px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.07+2,0+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0.07) 2%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.07) 0%,rgba(0,0,0,0.07) 2%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.07) 0%,rgba(0,0,0,0.07) 2%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
}

.inside-content{
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 60px 0;

 // @include breakpoint-down(sm) {

    & + footer{
      padding-top: 100px;
      padding-bottom: 42px;
      //padding-top: 60px;
      //padding-bottom: 60px;
      //border-top:solid 1px $color-silver;
    }

    display: block;
    height: auto;
    margin:0;
 // }

  @include breakpoint-down(sm) {
      padding:30px 0;  
      h3{
        font-size:1.2rem;
        line-height:1.6rem;
      }
   }

  h2{
    @include breakpoint-down(sm) {
      font-size: 26px;
      line-height: 34px;
    }
  }
  h2, h3{
    width: 80%;
    margin: 0 auto;
    @include breakpoint-down(sm) {
      width:100%;
    }
  }

  &.first{
    @include breakpoint-down(sm) {
      padding:30px 0;  
    }
  }
  
  &.bg-gray{
    background-color:$color-silver;
  }
  &.bg-midgray{
    background-color:$color-silver;
  }
  &.green{
    background-color:$color-green;

    h2{
      color:$color-white;
      text-transform:uppercase;
      width:100%;
      text-align:center;
      font-size: 2.8rem;
      margin-bottom:1rem;
    }
     @include breakpoint-up(md) {
        .row + .row{
          margin-top:1rem;  
        }
     }

  }

  &.banner-inside{
     min-height: 258px;
     background-size: cover;
     background-position: center;
     padding: 0;

    div{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.31+0,0+100;Neutral+Density */
      background: -moz-linear-gradient(45deg, rgba(0,0,0,0.31) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      width: 100%;
      height: 258px;
    }
  }

  .content-item{
    margin: 0 auto;
    font-size: 1.15rem;

    @include breakpoint-up(md) {
      width: 70%;
    }
    
    h4{
      font-weight:500;
      font-size: 1.6rem;
      text-align: center;
      margin-bottom: 5px;
      margin-top: 10px;
    }
  }

  &.inside-content-contact{
    //margin-bottom: 50px;

    ul {
      margin: 50px auto 0;
      display: inline-block;
      li{
        list-style: none;
        display: inline-block;
        text-align: center;
        margin: 0 30px;
        font-size: 0.9rem;
        @include breakpoint-up(md) {
          &:first-child {
            margin-left: 0;
          }
        }

        @include breakpoint-down(xs) {
          margin-bottom: 30px;

          &:last-child{
            margin-bottom: 0;
          }
        }

        a{
          color: $color-black;
          text-decoration:none;
          position: relative;

          &:before{
            content:'';
            display:block;
            border-bottom: solid 1px $color-green;
            bottom:0;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }

        a.contact-icons{
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          text-align: center;
          margin: 0 auto 15px;
          background-color: $color-white;
          padding-top: 28px;
          &:before{
            display: none;
          }
        }
      }
    }
  }

  &.listing{
    text-align: left;

    @include breakpoint-up(lg) {
      .container{
        width: 1000px;
      }
    }

    @include breakpoint-down(md) {
      p{
        margin-bottom: 0;
      }
      .col-2{
        font-size: 0.9rem;
      }
      .col-5:first-child{
        font-size: 1.25rem;
      }
    }

    .row{
      padding: 1.5rem 0;
      border-bottom:solid 1px $color-silver;
    }
  }

}

.inside-content-projects{
  position: relative;
  z-index: 1;
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -104px;
  margin-bottom: -100px;


  @include breakpoint-down(md) {
    padding-top: 15px;
    & + footer{
      padding-top: 100px;
      padding-bottom: 42px;
      
    }

    display: block;
    height: auto;
    margin:0;
  }


  a{
    color:$color-black;
    text-decoration: none;
    display: block;
    font-size: 1.7rem;
    padding:1rem;

     img{
       margin-bottom:15px;
     }

    @include breakpoint-up(md) {
      width: 200px;
      height: 200px;
      font-size: 1.5rem;
      display: table-cell;
      border-radius: 50%;
      vertical-align: middle;
      line-height: 1.8rem;
      margin: 0 auto;
    }
    
    @include breakpoint-up(lg) {
      font-size: 1.7rem;
      width: 270px;
      height: 270px;
    }

     @include breakpoint-down(sm) {
       padding-bottom:15px;
       line-height:1.8rem;
     }

    span{
      display: block;
      font-size: 0.9rem;
    }

  }

  .col{
    flex-grow: unset!important;

    a{
      position: relative;
      .item-desc{
        z-index: 1;
        color:$color-black;
        transition: all 0.3s ease-in;

        @include breakpoint-up(md) {
          font-size: 1.7rem;

          &.item-desc-hover {
            color: $color-white;
            transition: all 0.3s ease-in;
            text-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
          }
        }

        @include breakpoint-down(md) {
          font-size: 1.3rem;
        }
      }
      .item-bg{
        background-color: $color-silver;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top:50%;
        display: block;
        z-index: -1;

        @include breakpoint-up(md) {
          width: 200px;
          height: 200px;
          margin-top: -100px;
        }

        @include breakpoint-up(lg) {
          width: 270px;
          height: 270px;
          margin-top: -135px;
        }

        &.item-bg-hover{
          opacity: 0;
          transition: all 0.3s ease-in;
        }
      }
      .item-bg-lg{
        //transition: border-radius 0.2s ease-out;
        position: absolute;
        right: 0;
        top:0;
        display: block;
        transition: all 0.3s ease-in;
        opacity: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        &.item-bg-hover{
          opacity: 1;
          transition: all 0.3s ease-in;

        }

        &:before{
          content: '';
          display: block;
          position: absolute;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+79&0.75+0,0+78 */
          background: -moz-linear-gradient(45deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 78%, rgba(0,0,0,0) 79%); /* FF3.6-15 */
          background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 78%,rgba(0,0,0,0) 79%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(45deg, rgba(0,0,0,0.85) 0%,rgba(0,0,0,0) 78%,rgba(0,0,0,0) 79%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
          width: 100%;
          height: 100%;
        }
      }

      &.item-hover{
        border-radius: 0;
        transition: border-radius 0.2s ease-out;
        transition: height 0.5s ease-out;
      }

    }
  }
}

.inside-content-normal{
  position: relative;
  z-index: 1;
  padding: 60px 0;
  padding-right: 0px;
  padding-left: 0px;
  display: block;
  height: auto;
  margin: 0;

  @include breakpoint-down(sm) {
    padding: 40px 0;
  }
}

.inside-content-center{
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 60px 0;
  display: block;
  height: auto;
  font-size: 19px;
  line-height: 1.9rem;


  @include breakpoint-down(sm) {
    padding: 40px 0;

    img{
      margin-bottom: 15px;
    }
  }

  h4{
    font-size: 24px;
  }

  & + footer{
    padding-top: 100px;
    padding-bottom: 42px;
    //padding-top: 60px;
    //padding-bottom: 60px;
    //border-top:solid 1px $color-silver;
  }

  ul{
    display: inline-block;
    width: auto;
    text-align: left;

    li{
      margin-bottom: 5px;
      text-align: left;
    }
  }

  &.bg-gray{
    background-color:$color-silver;
  }

  .small{
    font-weight:bold;
    font-size: 16px;
  }
  h3{
    font-size: 30px;
  }
}

.inside-content-project{
  position: relative;
  z-index: 1;
  padding: 60px 0;
  font-size: 19px;
  line-height: 1.7rem;

  h4{
    font-size: 24px;
  }

  @include breakpoint-down(sm) {
    text-align: center;

    img{
      margin-bottom: 15px;
    }
  }

  #check-code {
    flex-direction: initial;

    .col-9 {
      width: 75% !important;
    }
    .col-3 {
      width: 25% !important;
    }
  }

  h3{
    font-size: 30px;
  }

  ul li{
    margin-bottom: 5px;
    text-align: left;
  }

  .row {
    //padding: 60px 0;
  }
  // @include breakpoint-down(sm) {

  & + footer{
    padding-top: 100px;
    padding-bottom: 42px;
    //border-top:solid 1px $color-silver;
  }

  display: block;
  height: auto;
  margin:0;
  // }

  @include breakpoint-down(sm) {
    padding:30px 0;
    h3{
      font-size: 26px;
      line-height: 34px;
    }
  }


  h2, h3{

    @include breakpoint-down(sm) {
      width:100%;
    }
  }

  &.first{
    @include breakpoint-down(sm) {
      padding:30px 0;
    }
  }

  &.gray{
    background-color:$color-silver;
  }
  &.green{
    background-color:$color-green;

    h2{
      color:$color-white;
      text-transform:uppercase;
      width:100%;
      text-align:center;
      font-size: 2.8rem;
    }

  }

  &.banner-inside{
    min-height: 258px;
    background-size: cover;
    background-position: center;
    padding: 0;

    div{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.31+0,0+100;Neutral+Density */
      background: -moz-linear-gradient(45deg, rgba(0,0,0,0.31) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(45deg, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      width: 100%;
      height: 258px;
    }
  }

  .content-item{
    margin: 0 auto;
    font-size: 1.1rem;

    @include breakpoint-up(md) {
      width: 70%;
    }

    h4{
      font-weight:500;
      font-size: 1.6rem;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }

  &.inside-content-contact{
    //margin-bottom: 50px;

    ul {
      margin: 50px auto 0;
      display: inline-block;
      li{
        list-style: none;
        display: inline-block;
        text-align: center;
        margin: 0 30px;
        font-size: 0.9rem;
        @include breakpoint-up(md) {
          &:first-child {
            margin-left: 0;
          }
        }

        @include breakpoint-down(sm) {
          margin-bottom: 30px;
        }

        a{
          color: $color-black;
          text-decoration:none;
          position: relative;

          &:before{
            content:'';
            display:block;
            border-bottom: solid 1px $color-green;
            bottom:0;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }

        a.contact-icons{
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          text-align: center;
          margin: 0 auto 15px;
          background-color: $color-white;
          padding-top: 28px;
          &:before{
            display: none;
          }
        }
      }
    }
  }
}

@include breakpoint-down(sm) {
  .row.gutters .col {
    margin-bottom: 0;
  }
}