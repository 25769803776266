.container-fluid{
  margin: 0 auto;
  width:100%;
}
.container {
  margin: 0 auto;
  padding: 0 $xs-space;
  width:100%;

  @include breakpoint(xs) {
    width: $xs-width;
    max-width: 100%;
  }
  @include breakpoint(sm) {
    width: $sm-width;
    max-width: 100%;
  }
  @include breakpoint(md) {
    width: $md-width;
    max-width: 100%;
  }
  @include breakpoint(lg) {
    width: $lg-width;
    max-width: 100%;
  }
}

.inside-content-projects{
  position: relative;
  z-index: 1;
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -88px;
  margin-bottom: -90px;
}