footer{
  position: relative;
  text-align:center;
  font-size:13px;
  z-index:100;

  .container{
    position: relative;

    .link-more{
      display: inline-block;
      text-transform: uppercase;
      height: auto;
      width: auto;
      color:$color-black;
      text-decoration: none;
      font-size: 1rem;
      position: absolute;
      right: 50px;
      top:-70px;

      @include breakpoint-down(md) {
        right: 50px;
      }
    }

    .link-less{
      display: inline-block;
      text-transform: uppercase;
      height: auto;
      width: auto;
      color:$color-black;
      text-decoration: none;
      font-size: 1rem;
      position: absolute;
      left: 50px;
      top:-70px;

      &.icon-arrow:after{
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        left: -30px;
      }

      @include breakpoint-down(md) {
        left: 50px;
      }
    }
  }
}