.loader{
	height: 100%;
	position: fixed;
	width: 100%;
	background-color: white;
	z-index: 10;
	margin-top: -104px;
	text-align: center;
	transition: all 0.3s ease;
	opacity: 1;

	@include breakpoint-down(md) {
		margin-top: -80px;
	}

	&.hiding{
		transition: all 0.3s ease;
		opacity: 0;
	}

	div{
		position: absolute;
		top: 50%;
		left: 50%;
	}
}
#loader-2 span{
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: $color-green;
	margin: 0px 2px;
}

#loader-2 span:nth-child(1){
	animation: bounce 1s ease-in-out infinite;
}

#loader-2 span:nth-child(2){
	animation: bounce 1s ease-in-out 0.33s infinite;
}

#loader-2 span:nth-child(3){
	animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce{
	0%, 75%, 100%{
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}

	25%{
		-webkit-transform: translateY(-10px);
		-ms-transform: translateY(-10px);
		-o-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}