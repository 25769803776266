.banner{
  position: relative;
  z-index: 1;
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -88px;
  margin-bottom: -100px;

  .container{
    margin: 0 auto;

    .content{
      width: 80%;
      margin: 0 auto;
      text-align: center;

      @include breakpoint-down(sm) {
        width:100%;

        h1{
          font-size: 26px;
          line-height: 34px;
        }
      }

      .link-more{
        color:$color-black;
        text-decoration: none;
        font-size: 1.4rem;
        margin-top: 2.5rem;
        display: inline-block;

        &:hover{
          color:$color-link-hover;
        }

        @include breakpoint-down(sm) {
          font-size: 1rem;
          margin-top: 1.5rem;
        }
      }

      .icon-arrow:after{
        width:70px;
      }

    }
  }
}